<template>
  <div class="issue g-safe-area">
    <div class="g-title">
      <img
        src="../../assets/img/arrow-left.svg"
        alt=""
        @click="$router.go(-1)"
      />
      <p>{{ t("use_problem") }}</p>
    </div>
    <el-form :model="form" :rules="formRules" ref="form">
      <div class="g-container">
        <h5 class="g-h5">{{ t("select_type_of_product_for_feedback") }}</h5>
        <section class="g-section">
          <div class="g-radio-box">
            <el-radio v-model="form.productType" label="APP"
              >ONTO {{ t("App_wallet") }}</el-radio
            >
            <el-radio v-model="form.productType" label="extension"
              >ONTO {{ t("extension_wallet") }}</el-radio
            >
          </div>
        </section>
      </div>
      <div class="g-container">
        <template v-if="form.productType === 'extension'">
          <h5 class="g-must g-h5">{{ t("version_number") }}</h5>

          <el-form-item>
            <section class="g-section">
              <el-input
                v-model="form.extVersion"
                placeholder="请选择"
                maxlength="10"
              ></el-input>
            </section>
            <sub class="g-sub">{{ t("version_number_sub_app") }}</sub>
          </el-form-item>

          <h5 class="g-must g-h5 g-top64">{{ t("os") }}</h5>
          <section class="g-section">
            <div class="g-radio-box">
              <el-radio v-model="form.extSystem" label="Windows"
                >Windows</el-radio
              >
              <el-radio v-model="form.extSystem" label="Mac">Mac</el-radio>
              <el-radio v-model="form.extSystem" label="Linux">Linux</el-radio>
            </div>
          </section>
        </template>
        <template v-else>
          <h5 class="g-must g-h5">{{ t("version_number") }}</h5>

          <el-form-item>
            <section class="g-section">
              <el-input
                v-model="form.appVersion"
                placeholder="请选择"
                maxlength="10"
              ></el-input>
            </section>
            <sub class="g-sub">{{ t("version_number_sub_ext") }}</sub>
          </el-form-item>

          <h5 class="g-must g-h5 g-top64">{{ t("os") }}</h5>
          <section class="g-section">
            <div class="g-radio-box">
              <el-radio v-model="form.appSystem" label="iOS">iOS</el-radio>
              <el-radio v-model="form.appSystem" label="Android"
                >Android</el-radio
              >
            </div>
          </section>
          <h5 class="g-h5 g-top64">{{ t("phone_model") }}</h5>
          <section class="g-section">
            <el-input
              v-model="form.appPhoneModel"
              :placeholder="t('please_enter_phone_model')"
            ></el-input>
          </section>
        </template>
      </div>
      <div class="g-container">
        <h5 class="g-must g-h5">{{ t("problem_types") }}</h5>
        <el-form-item prop="problemType">
          <section class="g-section">
            <el-select
              v-model="form.problemType"
              :placeholder="t('please_select_problem_types')"
            >
              <el-option
                v-for="item in problemTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </section>
        </el-form-item>
        <h5 class="g-must g-h5 g-top64">{{ t("title") }}</h5>
        <el-form-item prop="title">
          <section class="g-section">
            <el-input
              v-model="form.title"
              :placeholder="t('please_enter_title')"
              maxlength="50"
            ></el-input>
          </section>
        </el-form-item>
        <h5 class="g-must g-h5 g-top64">{{ t("describe") }}</h5>
        <el-form-item prop="des">
          <section class="g-section">
            <el-input
              type="textarea"
              :rows="2"
              :placeholder="t('please_enter_content')"
              autosize
              maxlength="500"
              show-word-limit
              resize="none"
              v-model="form.des"
            ></el-input>
          </section>
        </el-form-item>
        <sub class="g-sub">{{ t("issue_describe_sub") }}</sub>
        <h5 class="g-must g-h5 g-top64">{{ t("email") }}</h5>
        <el-form-item prop="email">
          <section class="g-section">
            <el-input
              v-model="form.email"
              :placeholder="t('please_enter_email')"
            ></el-input>
          </section>
        </el-form-item>
        <sub class="g-sub">{{ t("email_sub") }}</sub>

        <el-form-item prop="pictureList">
          <h5 class="g-h5 g-top64">{{ t("upload_screenshot") }}</h5>
          <section class="g-section upload-section">
            <ul v-if="form.pictureList.length > 0" class="uploaded-img-list">
              <li v-for="(img, index) in form.pictureList" :key="img">
                <img class="preview" :src="img" alt="" />
                <img
                  class="delete_icon"
                  src="../../assets/img/delete.svg"
                  alt=""
                  @click="deleteUploadImg(index)"
                />
              </li>
            </ul>
            <el-upload
              v-if="form.pictureList.length < 3"
              :action="origin + '/ontoservice/s3/h5/upload_file'"
              list-type="picture-card"
              :http-request="uploadImageHandle"
              accept=".jpg,.png,.jpeg"
              :show-file-list="false"
              :before-upload="beforeUploadImg"
            >
              <img src="../../assets/img/add.svg" alt="" />
            </el-upload>
          </section>
          <sub class="g-sub">{{ t("upload_screenshot_sub") }}</sub>
        </el-form-item>
      </div>
    </el-form>
    <el-button type="primary" style="margin-top: 32px" @click="submitHandler">{{
      t("submit")
    }}</el-button>
  </div>
</template>
<script lang="ts">
import { emailValidator } from "@/utils/validators";
import { defineComponent } from "vue";
import { submitFeedback, uploadFile } from "@/http/index";
import { useI18n } from "vue-i18n";
import { ElUpload, ElDialog, ElMessage } from "element-plus";

export default defineComponent({
  name: "Issue",
  setup() {
    const { t } = useI18n();
    const origin = process.env.VUE_APP_BASE_URL;
    return {
      t,
      origin,
    };
  },
  data() {
    return {
      form: {
        productType: "APP",
        appVersion: "",
        appSystem: "iOS",
        appPhoneModel: "",
        extVersion: "",
        extSystem: "Windows",
        problemType: "",
        title: "",
        des: "",
        email: "",
        pictureList: [] as any[],
      },
      formRules: {
        problemType: {
          required: true,
          message: this.t("please_select_problem_types"),
          trigger: "change",
        },
        title: {
          required: true,
          message: this.t("please_enter_title"),
          trigger: "change",
        },
        des: {
          required: true,
          message: this.t("please_enter_content"),
          trigger: "change",
        },
        email: {
          validator: emailValidator,
          trigger: "change",
        },
      },
    };
  },
  computed: {
    problemTypeOptions() {
      if (this.form.productType === "APP") {
        return [
          {
            value: this.$t("transfer_issue"),
            label: this.$t("transfer_issue"),
          },
          {
            value: this.$t("wallet_import"),
            label: this.$t("wallet_import"),
          },
          {
            value: this.$t("backup_mnemonic"),
            label: this.$t("backup_mnemonic"),
          },
          {
            value: this.$t("third_party_issues"),
            label: this.$t("third_party_issues"),
          },
          {
            value: this.$t("other"),
            label: this.$t("other"),
          },
        ];
      }
      return [
        {
          value: this.$t("transfer_issue"),
          label: this.$t("transfer_issue"),
        },
        {
          value: this.$t("wallet_import"),
          label: this.$t("wallet_import"),
        },
        {
          value: this.$t("backup_mnemonic"),
          label: this.$t("backup_mnemonic"),
        },
        {
          value: this.$t("third_party_issues"),
          label: this.$t("third_party_issues"),
        },
        {
          value: this.$t("other"),
          label: this.$t("other"),
        },
      ];
    },
  },
  methods: {
    async submitHandler() {
      // eslint-disable-next-line
      const formRef: any = this.$refs.form;
      formRef.validate(async (valid: boolean) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    async submit() {
      const {
        title,
        des,
        email,
        productType,
        appVersion,
        extVersion,
        appSystem,
        extSystem,
        problemType,
        appPhoneModel,
        pictureList,
      } = this.form;
      const isApp = productType === "APP";
      await submitFeedback({
        category: "problem",
        title,
        content: des,
        contact_info: email,
        source: isApp ? 1 : 2,
        version: isApp ? appVersion : extVersion,
        system: isApp ? appSystem : extSystem,
        problem: problemType,
        phone_model: appPhoneModel,
        picture_list: pictureList,
      });
      // todo toast
    },
    async uploadImageHandle(options: any) {
      const res = await uploadFile(options.file);
      this.form.pictureList.push(res);
    },

    deleteUploadImg(index: number) {
      this.form.pictureList.splice(index, 1);
    },
    beforeUploadImg(rawFile: any) {
      if (rawFile.size / 1048576 > 5) {
        ElMessage.error("Images cannot exceed 5MB");
        return false;
      }
      return true;
    },
  },
});
</script>
<style scoped lang="scss">
.upload-section {
  display: flex;
  column-gap: 12px;
}
.uploaded-img-list {
  display: flex;
  column-gap: 12px;
  li {
    height: 112px;
    width: 112px;
    position: relative;
    .preview {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .delete_icon {
      height: 20px;
      width: 20px;
      position: absolute;
      top: -6px;
      right: -6px;
      cursor: pointer;
    }
  }
}
</style>
