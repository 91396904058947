
import { emailValidator } from "@/utils/validators";
import { defineComponent } from "vue";
import { submitFeedback, uploadFile } from "@/http/index";
import { useI18n } from "vue-i18n";
import { ElUpload, ElDialog, ElMessage } from "element-plus";

export default defineComponent({
  name: "Issue",
  setup() {
    const { t } = useI18n();
    const origin = process.env.VUE_APP_BASE_URL;
    return {
      t,
      origin,
    };
  },
  data() {
    return {
      form: {
        productType: "APP",
        appVersion: "",
        appSystem: "iOS",
        appPhoneModel: "",
        extVersion: "",
        extSystem: "Windows",
        problemType: "",
        title: "",
        des: "",
        email: "",
        pictureList: [] as any[],
      },
      formRules: {
        problemType: {
          required: true,
          message: this.t("please_select_problem_types"),
          trigger: "change",
        },
        title: {
          required: true,
          message: this.t("please_enter_title"),
          trigger: "change",
        },
        des: {
          required: true,
          message: this.t("please_enter_content"),
          trigger: "change",
        },
        email: {
          validator: emailValidator,
          trigger: "change",
        },
      },
    };
  },
  computed: {
    problemTypeOptions() {
      if (this.form.productType === "APP") {
        return [
          {
            value: this.$t("transfer_issue"),
            label: this.$t("transfer_issue"),
          },
          {
            value: this.$t("wallet_import"),
            label: this.$t("wallet_import"),
          },
          {
            value: this.$t("backup_mnemonic"),
            label: this.$t("backup_mnemonic"),
          },
          {
            value: this.$t("third_party_issues"),
            label: this.$t("third_party_issues"),
          },
          {
            value: this.$t("other"),
            label: this.$t("other"),
          },
        ];
      }
      return [
        {
          value: this.$t("transfer_issue"),
          label: this.$t("transfer_issue"),
        },
        {
          value: this.$t("wallet_import"),
          label: this.$t("wallet_import"),
        },
        {
          value: this.$t("backup_mnemonic"),
          label: this.$t("backup_mnemonic"),
        },
        {
          value: this.$t("third_party_issues"),
          label: this.$t("third_party_issues"),
        },
        {
          value: this.$t("other"),
          label: this.$t("other"),
        },
      ];
    },
  },
  methods: {
    async submitHandler() {
      // eslint-disable-next-line
      const formRef: any = this.$refs.form;
      formRef.validate(async (valid: boolean) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    async submit() {
      const {
        title,
        des,
        email,
        productType,
        appVersion,
        extVersion,
        appSystem,
        extSystem,
        problemType,
        appPhoneModel,
        pictureList,
      } = this.form;
      const isApp = productType === "APP";
      await submitFeedback({
        category: "problem",
        title,
        content: des,
        contact_info: email,
        source: isApp ? 1 : 2,
        version: isApp ? appVersion : extVersion,
        system: isApp ? appSystem : extSystem,
        problem: problemType,
        phone_model: appPhoneModel,
        picture_list: pictureList,
      });
      // todo toast
    },
    async uploadImageHandle(options: any) {
      const res = await uploadFile(options.file);
      this.form.pictureList.push(res);
    },

    deleteUploadImg(index: number) {
      this.form.pictureList.splice(index, 1);
    },
    beforeUploadImg(rawFile: any) {
      if (rawFile.size / 1048576 > 5) {
        ElMessage.error("Images cannot exceed 5MB");
        return false;
      }
      return true;
    },
  },
});
